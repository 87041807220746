import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import Menu from './Menu';
import './i18n';
import reportWebVitals from './reportWebVitals';

// function Body() {
//   document.body.classList.add('body');
//   return (
//     <div />
//   );
// }

ReactDOM.render(
  <Suspense fallback="loading">
    <Router>
      <Menu />
    </Router>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
