import axios from 'axios';

let enterpriseIdUser = localStorage.getItem('enterpriseId');

export function setEnterpriseIdUser(id) {
  enterpriseIdUser = id;
}

let token = localStorage.getItem('token');

let config = {
  headers: { Authorization: `Bearer ${token}` },
};

let configAddImage = {
  headers: { Authorization: `Bearer ${token}` },
  'Content-Type': 'multipart/form-data',
};

export function refreshToken() {
  token = localStorage.getItem('token');
  config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  configAddImage = {
    headers: { Authorization: `Bearer ${token}` },
    'Content-Type': 'multipart/form-data',
  };
}

export function getEnterpriseIdUser() {
  return enterpriseIdUser;
}

const configImage = {
  // headers: { Authorization: `Bearer ${token}` },
  responseType: 'blob',
};

const apiUrl = 'https://api.tumesonero.es/';

const apiUrlPrivate = 'https://api.tumesonero.es/private/';

const urlRegister = `${apiUrl}register/`;

const urlLogin = `${apiUrl}login/`;

const urlVerification = `${apiUrl}verification/`;

const urlChangePassword = `${apiUrl}changepass`;

const urlAccount = `${apiUrlPrivate}account/auth/account/`;

const urlUser = `${apiUrlPrivate}user/userauth/`;

const urlShopPrivate = `${apiUrlPrivate}shop/`;

const urlMenuPrivate = `${apiUrlPrivate}menu/`;

const urlMenuPrivatePosition = `${apiUrlPrivate}menu/position/`;

const urlMenuPrivateVisible = `${apiUrlPrivate}menu/visible/`;

const urlProduct = `${apiUrl}product/`;

const urlProductPrivate = `${apiUrlPrivate}product/`;

const urlProductPrivatePosition = `${apiUrlPrivate}product/position/`;

const urlProductPrivateVisible = `${apiUrlPrivate}product/visible/`;

const urlCategoryPrivate = `${apiUrlPrivate}category/`;

const urlCategoryPrivatePosition = `${apiUrlPrivate}category/position/`;

const urlPicturePrivate = `${apiUrlPrivate}picture/`;

const urlShops = `${apiUrl}shop/shops/`;

const urlShop = `${apiUrl}shop/`;

const urlCategories = `${apiUrl}category/categories/`;

const urlProducts = `${apiUrl}product/products/`;

const urlMenus = `${apiUrl}menu/menus/`;

// const urlMenu = `${apiUrl}menu/`;

// const urlCategory = `${apiUrl}category/`;

const urlPicture = `${apiUrl}picture/`;

// const urlEnterprise = apiUrl + 'enterprise/'

// const urlEmployees = apiUrl + 'employees/';

const urlPromotion = `${apiUrl}promotion/`;

const urlPromotions = `${apiUrl}promotions/`;

export async function register(data) {
  const consulta = await axios.post(urlRegister, data);
  return consulta;
}

// VERIFY

export async function userLogin(data) {
  const consulta = await axios.post(urlLogin, data);
  return consulta;
}

export async function getAccount() {
  const account = (await axios.get(urlAccount, config)).data;
  return account;
}

export async function getUser() {
  const user = (await axios.get(urlUser, config)).data;
  return user.user;
}

export async function getVerification(email, verificationCode) {
  const verification = (await axios.get(`${urlVerification + email}&${verificationCode}`)).data;
  return verification;
}

export async function postChangePassword(data) {
  const consulta = await axios.post(urlChangePassword, data);
  return consulta;
}

// SHOP

export async function getShops(enterpriseId) {
  const shops = (await axios.get(urlShops + enterpriseId, config)).data;
  return shops.shops;
}

export async function addShop(data) {
  const consulta = await axios.post(urlShopPrivate, data, config);
  return consulta;
}

export async function getShop(enterpriseId, shopId) {
  const shop = (await axios.get(`${urlShop + enterpriseId}&${shopId}`, config)).data;
  return shop.shop;
}

export async function updateShop(shopId, data) {
  const resUpdateShop = await axios.put(urlShopPrivate + shopId, data, config);
  return resUpdateShop;
}

export async function deleteShop(shopId) {
  const removeShop = await axios.delete(urlShopPrivate + shopId, config);
  return removeShop;
}

export async function addPictureShop(data, shopId) {
  const consulta = await axios.post(`${urlPicturePrivate}shop/${shopId}`, data, configAddImage);
  return consulta;
}

export async function deleteImageShop(shopId) {
  const removeImageShop = await axios.delete(`${urlPicturePrivate}shop/${shopId}`, config);

  return removeImageShop;
}

export async function getImageShop(enterpriseId, shopId) {
  const res = await axios.get(`${urlPicture}shop/${enterpriseId}&${shopId}`, configImage);
  const imageShop = URL.createObjectURL(res.data);
  return imageShop;
}

// PRODUCT

export async function getProducts(enterpriseId, shopId) {
  const products = (await axios.get(`${urlProducts + enterpriseId}&${shopId}`, config)).data;
  return products.products;
}

export async function updateProduct(idProduct, data) {
  const resUpdateProduct = await axios.put(`${urlProductPrivate + idProduct}`, data, config);
  return resUpdateProduct;
}

export async function updatePositionProduct(idProduct, change) {
  const resUpdateProduct = await axios.put(`${urlProductPrivatePosition + idProduct}&${change}`, null, config);
  return resUpdateProduct.data;
}

export async function updatePositionVisible(idProduct) {
  const resVisibleProduct = await axios.put(`${urlProductPrivateVisible + idProduct}`, null, config);
  return resVisibleProduct.data;
}

export async function addProduct(data) {
  const consulta = await axios.post(urlProductPrivate, data, config);
  return consulta;
}

export async function deleteProduct(idProduct) {
  const removeProduct = await axios.delete(urlProductPrivate + idProduct, config);
  return removeProduct;
}

export async function addPictureProduct(data, productId) {
  const consulta = await axios.post(`${urlPicturePrivate}product/${productId}`, data, configAddImage);
  return consulta;
}

export async function getImageProduct(enterpriseId, idProduct) {
  const res = await axios.get(`${urlPicture}product/${enterpriseId}&${idProduct}`, configImage);
  const imageProduct = URL.createObjectURL(res.data);
  return imageProduct;
}

export async function deleteImageProduct(idProduct) {
  const removeImageProduct = await axios.delete(`${urlPicturePrivate}product/${idProduct}`, config);
  return removeImageProduct;
}

// export async function addLanguageProduct(idProduct, language) {
//   const languageProduct = await axios.post
// (`${urlProductPrivate}locale/${idProduct}&${language}`, config);
//   return languageProduct;
// }

// MENU

export async function getMenus(enterpriseId, shopId) {
  const menus = (await axios.get(`${urlMenus + enterpriseId}&${shopId}`, config)).data;
  return menus.menus;
}

export async function addMenu(data) {
  const consulta = await axios.post(urlMenuPrivate, data, config);
  return consulta;
}

export async function updateMenu(idMenu, data) {
  const resUpdateMenu = await axios.put(`${urlMenuPrivate + idMenu}`, data, config);
  return resUpdateMenu;
}

export async function updatePositionMenu(idMenu, change) {
  const resUpdateMenu = await axios.put(`${urlMenuPrivatePosition + idMenu}&${change}`, null, config);
  return resUpdateMenu.data;
}

export async function updatePositionVisibleMenu(idMenu) {
  const resVisibleMenu = await axios.put(`${urlMenuPrivateVisible + idMenu}`, null, config);
  return resVisibleMenu.data;
}

export async function deleteMenu(idMenu) {
  const removeMenu = (await axios.delete(urlMenuPrivate + idMenu, config)).data;
  return removeMenu;
}

export async function getImageMenu(enterpriseId, idMenu) {
  const res = await axios.get(`${urlPicture}menu/${enterpriseId}&${idMenu}`, configImage);
  const imageMenu = URL.createObjectURL(res.data);
  return imageMenu;
}

export async function addPictureMenu(data, menuId) {
  const consulta = await axios.post(`${urlPicturePrivate}menu/${menuId}`, data, configAddImage);
  return consulta;
}

export async function deleteImageMenu(idMenu) {
  const removeImageMenu = await axios.delete(`${urlPicturePrivate}menu/${idMenu}`, config);
  return removeImageMenu;
}

// export async function addLanguageMenu(idMenu, language) {
//   const languageMenu = await axios.post(`${urlMenuPrivate}locale/${idMenu}&${language}`, config);
//   return languageMenu;
// }

// CATEGORY

export async function getCategories(enterpriseId, shopId) {
  const categories = (await axios.get(`${urlCategories + enterpriseId}&${shopId}`, config)).data;
  return categories.categories;
}

export async function addCategory(data) {
  const consulta = await axios.post(urlCategoryPrivate, data, config);
  return consulta;
}

export async function deleteCategory(idCategory) {
  const removeCategory = await axios.delete(urlCategoryPrivate + idCategory, config);
  return removeCategory;
}

export async function updateCategory(idCategory, data) {
  const resUpdateCategory = await axios.put(`${urlCategoryPrivate + idCategory}`, data, config);
  return resUpdateCategory;
}

export async function updatePositionCategory(idCategory, change) {
  const resUpdatePositionCategory = await axios.put(`${urlCategoryPrivatePosition + idCategory}&${change}`, null, config);
  return resUpdatePositionCategory;
}

// export async function addLanguageCategory(idCategory, language) {
//   const languageProduct = await axios.post(`${urlCategoryPrivate}
// locale/${idCategory}&${language}`, config);
//   return languageProduct;
// }

// Add

export async function addPromotion(data, shopId) {
  const consulta = await axios.post(`${urlPromotion + enterpriseIdUser}&${shopId}`, data, config);
  return consulta;
}

export async function addPictureEmployee(data) {
  const consulta = await axios.post(`${urlPicture}employee/${enterpriseIdUser}`, data, configAddImage);
  return consulta;
}

export async function addPicturePromotion(data) {
  const consulta = await axios.post(`${urlPicture}promotion/${enterpriseIdUser}`, data, configAddImage);
  return consulta;
}

// get

export async function getProduct(idProduct) {
  let product;
  await axios.get(urlProduct + idProduct, config)
    .then((res) => {
      product = res.data;
    });
  return product;
}

export async function getPromotions(shopId) {
  let promotion;
  await axios.get(urlPromotions + shopId, config)
    .then((res) => {
      promotion = res.data;
    });
  return promotion;
}

export async function getImagePromotion(idPromotion) {
  const res = await axios.get(`${urlPromotion}promotion/${idPromotion}`, configImage);
  const imagePromotion = URL.createObjectURL(res.data);
  return imagePromotion;
}

// export async function getEnterprise(idEmployee) {
//     let enterprise
//     await axios.get(urlEnterprise + idEmployee, config)
//         .then(res => {
//             enterprise = res.data;
//         })
//     return enterprise;
// }

// export async function getEmployees() {
//     let employees
//     await axios.get(urlEmployees + enterpriseIdUser, config)
//         .then(res => {
//             employees = res.data;
//         })
//     return employees;
// }

// delete

export async function deletePromotion(idPromotion) {
  let removePromotion;
  await axios.delete(`${urlPromotion + enterpriseIdUser}&${idPromotion}`, config)
    .then((res) => {
      removePromotion = res.data;
    });
  return removePromotion;
}

// Update
