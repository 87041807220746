import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleIcon from '../Icons/google-play-badge-slim.svg';
import AppleIcon from '../Icons/app-store-badge-slim.svg';
import downloadIcon from '../Icons/adaptive-Logo.svg';

export default function BannerApp() {
  const { t } = useTranslation();

  return (
    <Button fullWidth href="https://play.google.com/store/apps/details?id=com.duonion.mesonero" style={{ textTransform: 'none' }}>
      <div className="banner" style={{ zIndex: 2 }}>
        <img
          width="48px"
          height="48px"
          src={downloadIcon}
          alt="imageMenu"
        />
        <Typography sx={{
          fontSize: '14px', color: 'white', marginLeft: '10px', marginRight: '10px',
        }}
        >
          {t('Banner.info')}
        </Typography>
        <div style={{ width: '25px' }}>
          <img
            width="25px"
            height="25px"
            src={GoogleIcon}
            alt="imageMenu"
          />
          <img
            width="25px"
            height="25px"
            src={AppleIcon}
            alt="imageMenu"
          />
        </div>
      </div>
    </Button>
  );
}
