import {
  Button,
  Card, CardContent, CardMedia, Grid, Modal, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import '../index.css';

import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import allergens from '../utils/alergens.json';
import traceAllergens from '../utils/traceAlergens.json';

import celery from '../Icons/allergen-celery.svg';
import crustaceans from '../Icons/allergen-crustaceans.svg';
import eggs from '../Icons/allergen-eggs.svg';
import fish from '../Icons/allergen-fish.svg';
import cereals from '../Icons/allergen-gluten.svg';
import lupin from '../Icons/allergen-lupin.svg';
import milk from '../Icons/allergen-milk.svg';
import molluscs from '../Icons/allergen-mollusks.svg';
import mustard from '../Icons/allergen-mustard.svg';
import nuts from '../Icons/allergen-nuts.svg';
import peanuts from '../Icons/allergen-peanuts.svg';
import sesame from '../Icons/allergen-sesame-seeds.svg';
import soya from '../Icons/allergen-soy.svg';
import sulphites from '../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../Icons/allergen-traces-eggs.svg';
import traceFish from '../Icons/allergen-traces-fish.svg';
import traceCereals from '../Icons/allergen-traces-gluten.svg';
import traceLupin from '../Icons/allergen-traces-lupin.svg';
import traceMilk from '../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../Icons/allergen-traces-mustard.svg';
import traceNuts from '../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../Icons/allergen-traces-soy.svg';
import traceSulphites from '../Icons/allergen-traces-sulphur-dioxide.svg';

import uppercase from '../uppercase/uppercase';

export default function InfoProductCart(props) {
  // const shopIdActual = localStorage.getItem('shop_id');
  // const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const { t } = useTranslation();

  const [showInfoAllergens, setShowInfoAllergens] = React.useState(false);
  const [allergensProduct, setAllergensProduct] = React.useState([]);
  const [traceAllergensProduct, setTraceAllergensProduct] = React.useState([]);

  const datos = props;
  // const idProduct = datos.product === undefined ? '' : datos.product.id;

  function closeModal() {
    datos.onHide();
  }

  function fetchData() {
    const auxAllergensProduct = [];
    const auxTraceAllergensProduct = [];
    if (datos.product !== undefined && datos.product.allergens.length !== 0) {
      for (let i = 0; i < datos.product.allergens.length; i += 1) {
        if (datos.product.allergens[i].id > 14) {
          auxTraceAllergensProduct.push(datos.product.allergens[i]);
        } else {
          auxAllergensProduct.push(datos.product.allergens[i]);
        }
      }
    }
    setAllergensProduct(auxAllergensProduct);
    setTraceAllergensProduct(auxTraceAllergensProduct);
  }

  useEffect(() => {
    fetchData();
  }, [datos]);

  return (
    showInfoAllergens
      ? (
        <Modal
          open={datos.show}
          onClose={datos.onHide}
        >
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              // padding: '16px',
              boxShadow: 24,
              p: 4,
              maxHeight: '95%',
              overflowY: 'scroll',
              width: '90%',
            }}
          >
            {/* <Button
              onClick={() => {
                setShowInfoAllergens(false);
              }}
            >
              <ArrowBackIcon />
            </Button> */}
            <Typography align="center" variant="h4" style={{ marginBottom: '15px' }}>
              <Button
                onClick={() => {
                  setShowInfoAllergens(false);
                }}
              >
                <ArrowBackIcon />
              </Button>
              {t('infoProductCart.allergens.title')}
            </Typography>
            <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
              {allergens.allergens.map((allergen) => {
                let allergenImg;
                let allergenName;
                switch (allergen.name) {
                  case 'celery':
                    allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Apio';
                    break;
                  case 'crustaceans':
                    allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Crustaceo';
                    break;
                  case 'eggs':
                    allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Huevo';
                    break;
                  case 'fish':
                    allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Pescado';
                    break;
                  case 'cereals':
                    allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Gluten';
                    break;
                  case 'lupin':
                    allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Lúpulo';
                    break;
                  case 'milk':
                    allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Leche';
                    break;
                  case 'molluscs':
                    allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Moluscos';
                    break;
                  case 'mustard':
                    allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Mostaza';
                    break;
                  case 'nuts':
                    allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Nueces';
                    break;
                  case 'peanuts':
                    allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Manices';
                    break;
                  case 'sesame':
                    allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Sésamo';
                    break;
                  case 'soya':
                    allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Soja';
                    break;
                  case 'sulphites':
                    allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Sulfitos';
                    break;
                  default:
                    allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Apio';
                    break;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                    {allergenImg}
                    <br />
                    {allergenName}
                  </Grid>
                );
              })}
            </Grid>
            <Typography align="center" variant="h4" style={{ marginBottom: '15px', marginTop: '15px' }}>
              {t('infoProductCart.traceAllergens.title')}
            </Typography>
            <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
              {traceAllergens.traceAllergens.map((traceAllergen) => {
                let traceAllergenImg;
                let traceAllergenName;
                switch (traceAllergen.name) {
                  case 'traceCelery':
                    traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Apio';
                    break;
                  case 'traceCrustaceans':
                    traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Crustaceo';
                    break;
                  case 'traceEggs':
                    traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Huevo';
                    break;
                  case 'traceFish':
                    traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Pescado';
                    break;
                  case 'traceCereals':
                    traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Gluten';
                    break;
                  case 'traceLupin':
                    traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Lúpulo';
                    break;
                  case 'traceMilk':
                    traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Leche';
                    break;
                  case 'traceMolluscs':
                    traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Moluscos';
                    break;
                  case 'traceMustard':
                    traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Mostaza';
                    break;
                  case 'traceNuts':
                    traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Nueces';
                    break;
                  case 'tracePeanuts':
                    traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Manices';
                    break;
                  case 'traceSesame':
                    traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Sésamo';
                    break;
                  case 'traceSoya':
                    traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Soja';
                    break;
                  case 'traceSulphites':
                    traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Sulfitos';
                    break;
                  default:
                    traceAllergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Apio';
                    break;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                    {traceAllergenImg}
                    <br />
                    {traceAllergenName}
                  </Grid>
                );
              })}
              {/* </FormGroup> */}
            </Grid>
          </Card>
        </Modal>
      ) : (
        <Modal
          open={datos.show}
          onClose={datos.onHide}
        >
          {datos.product === undefined
            ? (
              <Typography variant="spam">
                {t('infoProductCart.noInformation')}
              </Typography>
            )
            : (
              datos.product !== undefined
              && (
                <Card
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // maxWidth: '2000px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#FFFFFF',
                    // border: '2px solid #000',
                    // padding: '16px',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '95%',
                    overflowY: 'scroll',
                    width: '90%',
                  }}
                >
                  {datos.product.pictures.length !== 0
                    ? (
                      <CardMedia
                        component="img"
                        height="194"
                        image={datos.image}
                        alt="Paella dish"
                        sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                      />
                    ) : (
                      <div
                        style={{
                          height: '194px',
                          width: '100%',
                          // padding: '8px',
                          boxSizing: 'border-box',
                          backgroundColor: '#ECECEC',
                        }}
                      />
                    )}
                  <CardContent>
                    <Typography align="center" variant="h4" style={{ marginBottom: '4px' }}>
                      {uppercase(datos.product.name[datos.language])}
                    </Typography>
                    <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                      {uppercase(datos.product.description[datos.language])}
                    </Typography>
                    <div className="divider div-transparent" style={datos.product.ingredients[datos.language] === null ? { display: 'none' } : {}} />
                    <div style={datos.product.ingredients[datos.language] === null ? { display: 'none' } : {}}>
                      <Typography align="center" variant="h6">
                        {t('infoProductCart.ingredients')}
                      </Typography>
                      <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                        {uppercase(datos.product.ingredients[datos.language])}
                      </Typography>
                    </div>
                    <div className="divider div-transparent" />
                    <Typography align="center" variant="h6">
                      {t('infoProductCart.allergens.title')}
                      <Button
                        onClick={() => {
                          setShowInfoAllergens(true);
                        }}
                        style={{ minWidth: '0px' }}
                      >
                        <InfoOutlinedIcon style={{ color: 'black', width: '24px' }} />
                      </Button>
                    </Typography>
                    {allergensProduct.length !== 0
                      ? (
                        <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                          {/* <Grid item lg={12} xs={12}> */}
                          {allergensProduct.map((allergenProduct) => {
                            switch (allergenProduct.name) {
                              case 'celery':
                                return (
                                  <img
                                    src={celery}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'crustaceans':
                                return (
                                  <img
                                    src={crustaceans}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'eggs':
                                return (
                                  <img
                                    src={eggs}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'fish':
                                return (
                                  <img
                                    src={fish}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'cereals':
                                return (
                                  <img
                                    src={cereals}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'lupin':
                                return (
                                  <img
                                    src={lupin}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'milk':
                                return (
                                  <img
                                    src={milk}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'molluscs':
                                return (
                                  <img
                                    src={molluscs}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'mustard':
                                return (
                                  <img
                                    src={mustard}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'nuts':
                                return (
                                  <img
                                    src={nuts}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'peanuts':
                                return (
                                  <img
                                    src={peanuts}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'sesame':
                                return (
                                  <img
                                    src={sesame}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'soya':
                                return (
                                  <img
                                    src={soya}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'sulphites':
                                return (
                                  <img
                                    src={sulphites}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              default:
                                return (
                                  <img
                                    src={celery}
                                    style={{ height: 32, width: 32 }}
                                    key={allergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                            }
                          })}
                          {/* </Grid> */}
                        </Grid>
                      )
                      : (
                        <Typography variant="body1" align="center">
                          {t('infoProductCart.allergens.noAllergens')}
                        </Typography>
                      )}
                    <div className="divider div-transparent" />
                    <Typography align="center" variant="h6">
                      {t('infoProductCart.traceAllergens.title')}
                      <Button
                        onClick={() => {
                          setShowInfoAllergens(true);
                        }}
                        style={{ minWidth: '0px' }}
                      >
                        <InfoOutlinedIcon style={{ color: 'black', width: '24px' }} />
                      </Button>
                    </Typography>
                    {traceAllergensProduct.length !== 0
                      ? (
                        <Grid container style={{ marginBottom: '5%', justifyContent: 'center' }}>
                          {/* <Grid item lg={12} xs={12}> */}
                          {traceAllergensProduct.map((traceAllergenProduct) => {
                            switch (traceAllergenProduct.name) {
                              case 'tcelery':
                                return (
                                  <img
                                    src={traceCelery}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tcrustaceans':
                                return (
                                  <img
                                    src={traceCrustaceans}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'teggs':
                                return (
                                  <img
                                    src={traceEggs}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tfish':
                                return (
                                  <img
                                    src={traceFish}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tcereals':
                                return (
                                  <img
                                    src={traceCereals}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tlupin':
                                return (
                                  <img
                                    src={traceLupin}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tmilk':
                                return (
                                  <img
                                    src={traceMilk}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tmolluscs':
                                return (
                                  <img
                                    src={traceMolluscs}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tmustard':
                                return (
                                  <img
                                    src={traceMustard}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tnuts':
                                return (
                                  <img
                                    src={traceNuts}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tpeanuts':
                                return (
                                  <img
                                    src={tracePeanuts}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tsesame':
                                return (
                                  <img
                                    src={traceSesame}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tsoya':
                                return (
                                  <img
                                    src={traceSoya}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              case 'tsulphites':
                                return (
                                  <img
                                    src={traceSulphites}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                              default:
                                return (
                                  <img
                                    src={traceCelery}
                                    style={{ height: 32, width: 32 }}
                                    key={traceAllergenProduct.id}
                                    alt="allergen"
                                  />
                                );
                            }
                          })}
                          {/* </Grid> */}
                        </Grid>
                      )
                      : (
                        <Typography variant="body1" align="center">
                          {t('infoProductCart.allergens.noAllergens')}
                        </Typography>
                      )}
                    <div className="divider div-transparent" />
                    <Typography align="center" variant="h6">
                      {t('infoProductCart.price')}
                    </Typography>
                    <Typography align="center" variant="body1">
                      {datos.product.price}
                      {' '}
                      €
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                      <Button variant="contained" onClick={() => { closeModal(); }}>
                        {t('infoProductCart.closeButton')}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )
            )}
        </Modal>
      )
  );
}
