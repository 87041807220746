import {
  Button,
  Card, CardContent, CardMedia, Grid, Modal, Typography,
} from '@mui/material';
import React from 'react';
import '../index.css';
import i18n from 'i18next';

import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import allergens from '../utils/alergens.json';
import traceAllergens from '../utils/traceAlergens.json';

import celery from '../Icons/allergen-celery.svg';
import crustaceans from '../Icons/allergen-crustaceans.svg';
import eggs from '../Icons/allergen-eggs.svg';
import fish from '../Icons/allergen-fish.svg';
import cereals from '../Icons/allergen-gluten.svg';
import lupin from '../Icons/allergen-lupin.svg';
import milk from '../Icons/allergen-milk.svg';
import molluscs from '../Icons/allergen-mollusks.svg';
import mustard from '../Icons/allergen-mustard.svg';
import nuts from '../Icons/allergen-nuts.svg';
import peanuts from '../Icons/allergen-peanuts.svg';
import sesame from '../Icons/allergen-sesame-seeds.svg';
import soya from '../Icons/allergen-soy.svg';
import sulphites from '../Icons/allergen-sulphur-dioxide.svg';

import traceCelery from '../Icons/allergen-traces-celery.svg';
import traceCrustaceans from '../Icons/allergen-traces-crustaceans.svg';
import traceEggs from '../Icons/allergen-traces-eggs.svg';
import traceFish from '../Icons/allergen-traces-fish.svg';
import traceCereals from '../Icons/allergen-traces-gluten.svg';
import traceLupin from '../Icons/allergen-traces-lupin.svg';
import traceMilk from '../Icons/allergen-traces-milk.svg';
import traceMolluscs from '../Icons/allergen-traces-mollusks.svg';
import traceMustard from '../Icons/allergen-traces-mustard.svg';
import traceNuts from '../Icons/allergen-traces-nuts.svg';
import tracePeanuts from '../Icons/allergen-traces-peanuts.svg';
import traceSesame from '../Icons/allergen-traces-sesame-seeds.svg';
import traceSoya from '../Icons/allergen-traces-soy.svg';
import traceSulphites from '../Icons/allergen-traces-sulphur-dioxide.svg';

import uppercase from '../uppercase/uppercase';

export default function InfoProductCart(props) {
  // const shopIdActual = localStorage.getItem('shop_id');
  // const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'));
  const { t } = useTranslation();

  const [showInfoAllergens, setShowInfoAllergens] = React.useState(false);

  const datos = props;

  // const idProduct = datos.product === undefined ? '' : datos.product.id;

  function closeModal() {
    datos.onHide();
  }

  return (
    showInfoAllergens
      ? (
        <Modal
          open={datos.show}
          onClose={datos.onHide}
        >
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // maxWidth: '2000px',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              // border: '2px solid #000',
              // padding: '16px',
              boxShadow: 24,
              p: 4,
              maxHeight: '95%',
              overflowY: 'scroll',
              width: '90%',
            }}
          >
            {/* <Button
              onClick={() => {
                setShowInfoAllergens(false);
              }}
            >
              <ArrowBackIcon />
            </Button> */}
            <Typography align="center" variant="h4" style={{ marginBottom: '15px' }}>
              <Button
                onClick={() => {
                  setShowInfoAllergens(false);
                }}
              >
                <ArrowBackIcon />
              </Button>
              Alérgenos
            </Typography>
            <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
              {allergens.allergens.map((allergen) => {
                let allergenImg;
                let allergenName;
                switch (allergen.name) {
                  case 'celery':
                    allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Apio';
                    break;
                  case 'crustaceans':
                    allergenImg = <img src={crustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Crustaceo';
                    break;
                  case 'eggs':
                    allergenImg = <img src={eggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Huevo';
                    break;
                  case 'fish':
                    allergenImg = <img src={fish} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Pescado';
                    break;
                  case 'cereals':
                    allergenImg = <img src={cereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Gluten';
                    break;
                  case 'lupin':
                    allergenImg = <img src={lupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Lúpulo';
                    break;
                  case 'milk':
                    allergenImg = <img src={milk} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Leche';
                    break;
                  case 'molluscs':
                    allergenImg = <img src={molluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Moluscos';
                    break;
                  case 'mustard':
                    allergenImg = <img src={mustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Mostaza';
                    break;
                  case 'nuts':
                    allergenImg = <img src={nuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Nueces';
                    break;
                  case 'peanuts':
                    allergenImg = <img src={peanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Manices';
                    break;
                  case 'sesame':
                    allergenImg = <img src={sesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Sésamo';
                    break;
                  case 'soya':
                    allergenImg = <img src={soya} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Soja';
                    break;
                  case 'sulphites':
                    allergenImg = <img src={sulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Sulfitos';
                    break;
                  default:
                    allergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    allergenName = 'Apio';
                    break;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                    {allergenImg}
                    <br />
                    {allergenName}
                  </Grid>
                );
              })}
            </Grid>
            <Typography align="center" variant="h4" style={{ marginBottom: '15px', marginTop: '15px' }}>
              Traza Alérgenos
            </Typography>
            <Grid container rowSpacing={2} style={{ justifyContent: 'center' }}>
              {traceAllergens.traceAllergens.map((traceAllergen) => {
                let traceAllergenImg;
                let traceAllergenName;
                switch (traceAllergen.name) {
                  case 'traceCelery':
                    traceAllergenImg = <img src={traceCelery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Apio';
                    break;
                  case 'traceCrustaceans':
                    traceAllergenImg = <img src={traceCrustaceans} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Crustaceo';
                    break;
                  case 'traceEggs':
                    traceAllergenImg = <img src={traceEggs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Huevo';
                    break;
                  case 'traceFish':
                    traceAllergenImg = <img src={traceFish} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Pescado';
                    break;
                  case 'traceCereals':
                    traceAllergenImg = <img src={traceCereals} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Gluten';
                    break;
                  case 'traceLupin':
                    traceAllergenImg = <img src={traceLupin} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Lúpulo';
                    break;
                  case 'traceMilk':
                    traceAllergenImg = <img src={traceMilk} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Leche';
                    break;
                  case 'traceMolluscs':
                    traceAllergenImg = <img src={traceMolluscs} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Moluscos';
                    break;
                  case 'traceMustard':
                    traceAllergenImg = <img src={traceMustard} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Mostaza';
                    break;
                  case 'traceNuts':
                    traceAllergenImg = <img src={traceNuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Nueces';
                    break;
                  case 'tracePeanuts':
                    traceAllergenImg = <img src={tracePeanuts} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Manices';
                    break;
                  case 'traceSesame':
                    traceAllergenImg = <img src={traceSesame} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Sésamo';
                    break;
                  case 'traceSoya':
                    traceAllergenImg = <img src={traceSoya} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Soja';
                    break;
                  case 'traceSulphites':
                    traceAllergenImg = <img src={traceSulphites} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Sulfitos';
                    break;
                  default:
                    traceAllergenImg = <img src={celery} style={{ height: 32, width: 32 }} alt="allergen" />;
                    traceAllergenName = 'Apio';
                    break;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item lg={3} xs={4} style={{ textAlign: 'center' }}>
                    {traceAllergenImg}
                    <br />
                    {traceAllergenName}
                  </Grid>
                );
              })}
              {/* </FormGroup> */}
            </Grid>
          </Card>
        </Modal>
      ) : (
        <Modal
          open={datos.show}
          onClose={datos.onHide}
        >
          {datos.menu === undefined
            ? (
              <Typography variant="spam">
                {t('infoProductCart.noInformation')}
              </Typography>
            )
            : (
              datos.menu !== undefined
              && (
                <Card
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // maxWidth: '2000px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#FFFFFF',
                    // border: '2px solid #000',
                    // padding: '16px',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '95%',
                    overflowY: 'scroll',
                    width: '90%',
                  }}
                >
                  {datos.menu.pictures.length !== 0
                    ? (
                      <CardMedia
                        component="img"
                        height="194"
                        image={datos.imageMenu}
                        alt="Paella dish"
                        sx={{ objectFit: 'contain', padding: '8px', boxSizing: 'border-box' }}
                      />
                    ) : (
                      <div
                        style={{
                          height: '194px',
                          width: '100%',
                          // padding: '8px',
                          boxSizing: 'border-box',
                          backgroundColor: '#ECECEC',
                        }}
                      />
                    )}
                  <CardContent>
                    <Typography align="center" variant="h4" style={{ marginBottom: '4px' }}>
                      {uppercase(datos.menu.name[datos.language])}
                    </Typography>
                    <Typography align="center" variant="body1" style={{ marginBottom: '5%' }}>
                      {uppercase(datos.menu.description[datos.language])}
                    </Typography>
                    {datos.menu.products.map((product, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <CardContent key={index} style={{ padding: '4px' }}>
                        <Grid
                          container
                          style={{
                            border: '1px solid #EEEEEE',
                            borderRadius: '10px',
                            height: '84px',
                          }}
                        >
                          <Grid item lg={3} xs={3} style={{ height: '100%' }}>
                            {datos.imagesProducts[index] !== null
                              ? (
                                <img
                                  src={datos.imagesProducts[index]}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                  }}
                                  alt="imageProduct"
                                />
                              )
                              : (
                                <div
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: '#ECECEC',
                                    objectFit: 'cover',
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                  }}
                                />
                              )}
                          </Grid>
                          <Grid item lg={9} xs={9} style={{ paddingLeft: '20px', height: '100%' }}>
                            <Grid container style={{ height: '100%' }}>
                              <Grid item lg={12} xs={12} style={{ marginTop: '2px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                  <div>
                                    <Typography
                                      sx={{
                                        fontSize: '16px', fontWeight: '600',
                                      }}
                                      noWrap
                                    >
                                      {product.name[i18n.language] === null
                                        ? uppercase(product.name.es)
                                        : uppercase(product.name[i18n.language])}
                                    </Typography>
                                  </div>
                                  <div style={{ flexGrow: 1 }}>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        lineHeight: '1.1',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.description[i18n.language] === null
                                        ? uppercase(product.description.es)
                                        : uppercase(product.description[i18n.language])}
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {product.allergens.map((allergen) => {
                                      switch (allergen.name) {
                                        case 'celery':
                                          return (
                                            <img
                                              src={celery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'crustaceans':
                                          return (
                                            <img
                                              src={crustaceans}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'eggs':
                                          return (
                                            <img
                                              src={eggs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'fish':
                                          return (
                                            <img
                                              src={fish}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'cereals':
                                          return (
                                            <img
                                              src={cereals}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'lupin':
                                          return (
                                            <img
                                              src={lupin}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'milk':
                                          return (
                                            <img
                                              src={milk}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'molluscs':
                                          return (
                                            <img
                                              src={molluscs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'mustard':
                                          return (
                                            <img
                                              src={mustard}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'nuts':
                                          return (
                                            <img
                                              src={nuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'peanuts':
                                          return (
                                            <img
                                              src={peanuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'sesame':
                                          return (
                                            <img
                                              src={sesame}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'soya':
                                          return (
                                            <img
                                              src={soya}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 'sulphites':
                                          return (
                                            <img
                                              src={sulphites}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_celery':
                                          return (
                                            <img
                                              src={traceCelery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_crustaceans':
                                          return (
                                            <img
                                              src={traceCrustaceans}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_eggs':
                                          return (
                                            <img
                                              src={traceEggs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_fish':
                                          return (
                                            <img
                                              src={traceFish}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_cereals':
                                          return (
                                            <img
                                              src={traceCereals}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_lupin':
                                          return (
                                            <img
                                              src={traceLupin}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_milk':
                                          return (
                                            <img
                                              src={traceMilk}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_molluscs':
                                          return (
                                            <img
                                              src={traceMolluscs}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_mustard':
                                          return (
                                            <img
                                              src={traceMustard}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_nuts':
                                          return (
                                            <img
                                              src={traceNuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_peanuts':
                                          return (
                                            <img
                                              src={tracePeanuts}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_sesame':
                                          return (
                                            <img
                                              src={traceSesame}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_soya':
                                          return (
                                            <img
                                              src={traceSoya}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        case 't_sulphites':
                                          return (
                                            <img
                                              src={traceSulphites}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                        default:
                                          return (
                                            <img
                                              src={celery}
                                              style={{ height: 16, width: 16 }}
                                              key={allergen.id}
                                              alt="allergen"
                                            />
                                          );
                                      }
                                    })}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                      <Button variant="contained" onClick={() => { closeModal(); }}>
                        {t('infoProductCart.closeButton')}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )
            )}
        </Modal>
      )
  );
}
